<template>
  <div>
    <b-row class="align-items-baseline">
      <b-col cols="8">
        <h4>{{ $t('Payment Gateways') }}</h4>
      </b-col>
      <b-col
        class="text-right"
        cols="4"
      >
        <b-button
          v-b-modal.add-edit-gateway
          variant="outline-success"
          class="p-1"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('Add') }}
        </b-button>
      </b-col>
    </b-row>
    <hr>

    <b-overlay :show="isProcessing">
      <b-row v-if="gateways.length">
        <b-col
          v-for="gateway in gateways"
          :key="`${gateway.information.id}_${gateway.information.__typename}`"
          cols="12"
          md="4"
        >
          <b-card
            :border-variant="gateway.isDefault ? 'success' : ''"
            no-body
          >
            <b-card-body class="d-flex flex-column justify-content-between">
              <b-row>
                <b-col cols="10">
                  <h4 class="mb-1">
                    {{ gateway.alias }}
                  </h4>
                </b-col>
                <b-col cols="2">
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    right
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        class="float-right"
                        size="16"
                      />
                    </template>
                    <b-dropdown-item
                      @click="showEditModal(gateway)"
                    >
                      <feather-icon
                        icon="EditIcon"
                      />
                      {{ $t('Edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!gateway.isDefault"
                      @click="showConfirmModal(gateway)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                      />
                      {{ $t('Set Default') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2">
                  <feather-icon
                    v-if="gateway.isDefault"
                    icon="CheckIcon"
                    class="float-right"
                    color="#28c76f"
                    size="24"
                  />
                </b-col>
                <b-col md="10">
                  <p class="float-right mb-0">
                    {{ gateway.information.__typename.replace('BillingInformation', '') }}
                  </p>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-alert
        v-else
        class="p-1"
        show
      >
        <feather-icon
          icon="InfoIcon"
          size="20"
          class="mr-1"
        />
        <span>{{ $t('gateways.no-data') }}</span>
      </b-alert>
    </b-overlay>

    <!-- confirmation modal -->
    <b-modal
      id="default-payment-modal"
      :title="$t('Default Payment Gateway')"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      @ok="updateDefaultBillingInfo"
    >
      <span v-if="selectedGateway">
        {{ $t('gateways.make-default-gateway', {gateway: selectedGateway.alias}) }}
      </span>
    </b-modal>

    <!-- Add new payment gateway -->
    <payment-gateway-options
      ref="paymentGatewayOptions"
      :mode="mode"
      :gateway="selectedGateway"
      @close="() => mode = 'create'"
      @refetch="getCompanyPaymentGateways"
    />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal, BButton, BCard, BCardBody, BOverlay, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import useApollo from '@/plugins/graphql/useApollo'
import PaymentGatewayOptions from './PaymentGatewayOptions.vue'

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BCard,
    BCardBody,
    BOverlay,
    BDropdown,
    BAlert,
    BDropdownItem,
    FeatherIcon,
    PaymentGatewayOptions,
  },
  data() {
    return {
      companyUid: this.$store.state.project.selectedCompany,
      gateways: {},
      selectedGateway: {},
      isProcessing: false,
      mode: 'create',
    }
  },
  mounted() {
    this.getCompanyPaymentGateways()
  },
  methods: {
    getCompanyPaymentGateways() {
      this.isProcessing = true
      useApollo.company.getCompanyPaymentGateways({ id: this.companyUid }).then(response => {
        this.gateways = response.data.company.paymentGateways.data
      }).finally(() => { this.isProcessing = false })
    },
    showConfirmModal(gateway) {
      if (gateway.isDefault) return
      this.selectedGateway = gateway
      this.$bvModal.show('default-payment-modal')
    },
    updateDefaultBillingInfo() {
      useApollo.company.updateDefaultBillingInfo({
        companyUid: this.companyUid,
        input: {
          id: this.selectedGateway.information.id,
          typeName: this.selectedGateway.information.__typename,
        },
      }).then(response => {
        this.showSuccess(response.data.updateDefaultBillingInformation.message)
        this.getCompanyPaymentGateways()
      })
    },
    showEditModal(gateway) {
      this.selectedGateway = gateway
      this.mode = 'edit'
      this.$nextTick(() => {
        this.$bvModal.show('add-edit-gateway')
      })
    },
  },
}
</script>

<style scoped>
.card {
  min-height: 125px;
  box-shadow: 0 4px 15px 4px rgb(34 41 47 / 10%) !important;
}
.dark-layout .card {
  background-color: #283046 !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18) !important;
}
</style>
