<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="$t('gateways.airwallex-setup')"
          class="font-weight-bold"
        />
        <b-form-group :label="$t('Base URL')">

          <validation-provider
            #default="{ errors }"
            name="base URL"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-input-group-prepend is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="GlobeIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.url"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :placeholder="$t('gateways.airwallex-api-url')"
              />

            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group :label="$t('Client ID')">

          <validation-provider
            #default="{ errors }"
            name="client ID"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-input-group-prepend is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="UserIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.clientId"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :placeholder="$t('gateways.airwallex-client-id')"
              />

            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group :label="$t('Client Secret')">
          <validation-provider
            #default="{ errors }"
            name="client secret"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
              :state="errors.length > 0 ? false:null"
            >
              <b-form-input
                v-model="form.clientSecret"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>

        </b-form-group>
        <b-form-group :label="$t('Transfer Success Key')">

          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="form.transferSuccessKey"
              class="form-control-merge"
              :type="passwordFieldType"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>

        </b-form-group>
        <b-form-group :label="$t('Transfer Created Key')">

          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="form.transferCreatedKey"
              class="form-control-merge"
              :type="passwordFieldType"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: {
    information: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      form: {
        url: 'https://airwallex.com/api/v1',
        clientId: '',
        clientSecret: '',
        transferCreatedKey: '',
        transferSuccessKey: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    mode: {
      handler(val) {
        if (val === 'edit') {
          this.form = this.information
        }
      },
      immediate: true,
    },
  },

}
</script>
