<template>
  <b-modal
    id="add-edit-gateway"
    no-close-on-backdrop
    :title="mode === 'edit' ? $t('gateways.update') : $t('gateways.add-new')"
    :ok-title="mode === 'edit' ? $t('Update') : $t('Submit')"
    :cancel-title="$t('Cancel')"
    @ok="handleSubmit"
    @hidden="clearForm"
  >
    <validation-observer ref="paymentGatewayOptionsForm">
      <b-form-group :label="$t('Alias')">
        <validation-provider
          #default="{ errors }"
          :name="$t('Billing Information Alias')"
          rules="required"
        >
          <b-form-input
            v-model="form.alias"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            :placeholder="$t('Billing Information Alias')"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('Payment Gateway')"
      >
        <v-select
          v-model="form.selectedGateway"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          label="label"
          :options="gateways"
          :disabled="mode === 'edit' ? true : false"
        />
      </b-form-group>
      <airwallex
        v-if="form.selectedGateway.type==='AirwallexBillingInformation'"
        ref="billingOptions"
        :information="information"
        :mode="mode"
      />
      <stripe
        v-if="form.selectedGateway.type==='StripeBillingInformation'"
        ref="billingOptions"
        :information="information"
        :mode="mode"
      />

    </validation-observer>
  </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useApollo from '@/plugins/graphql/useApollo'
import Stripe from './gateways/Stripe.vue'
import Airwallex from './gateways/Airwallex.vue'

export default {
  components: {
    vSelect,
    BFormGroup,
    Stripe,
    Airwallex,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    gateway: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      companyUid: this.$store.state.project.selectedCompany,
      information: {},
      form: {
        alias: '',
        selectedGateway: {
          type: 'AirwallexBillingInformation',
          label: 'Airwallex',
        },
      },
      methods: {
        StripeBillingInformation: useApollo.company.updateStripeBillingInformation,
        AirwallexBillingInformation: useApollo.company.updateAirwallexBillingInformation,
      },
      gateways: [
        {
          type: 'AirwallexBillingInformation',
          label: 'Airwallex',
        },
        // {
        //   type: 'StripeBillingInformation',
        //   label: 'Stripe',
        // },
      ],
    }
  },
  watch: {
    mode(val) {
      if (val === 'edit') {
        this.form = {
          alias: this.gateway.alias,
          selectedGateway: {
            type: this.gateway.information.__typename,
            label: this.gateway.information.__typename.replace('BillingInformation', ''),
          },
        }
        this.information = this.gateway.information
      }
    },
  },
  methods: {
    handleSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.paymentGatewayOptionsForm.validate().then(success => {
        if (success) {
          if (this.mode === 'edit') this.updateCompanyPaymentGateway()
          else this.createCompanyPaymentGateway()
        }
      })
    },
    createCompanyPaymentGateway() {
      const { form } = this.$refs.billingOptions
      const gatewayOptions = this.form
      const apiData = {
        companyUid: this.companyUid,
        alias: gatewayOptions.alias,
        input: form,
      }
      this.methods[gatewayOptions.selectedGateway.type](apiData).then(() => {
        this.showSuccess('Payment Gateway Added Successfully')
        this.$emit('refetch')
        this.$bvModal.hide('add-edit-gateway')
        this.$nextTick(() => { this.clearForm() })
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    updateCompanyPaymentGateway() {
      const { form } = this.$refs.billingOptions
      const gatewayOptions = this.form
      const apiData = {
        companyUid: this.companyUid,
        alias: gatewayOptions.alias,
        input: form,
      }
      delete apiData.input.__typename

      this.methods[gatewayOptions.selectedGateway.type](apiData).then(() => {
        this.showSuccess('Payment Gateway Updated Successfully')
        this.$emit('refetch')
        this.$bvModal.hide('add-edit-gateway')
        this.$nextTick(() => { this.clearForm() })
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    clearForm() {
      this.form = {
        alias: '',
        selectedGateway: {
          type: 'AirwallexBillingInformation',
          label: 'Airwallex',
        },
      }
      this.$emit('close')
    },
  },
}
</script>

<style>
.vs__open-indicator {
  fill: #6e6b7b !important;
}
</style>
